import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';


import '../css/About.css'
import { IconCake, IconCar, IconPhoneCall, IconHome, IconBrandAsana, IconNotebook, IconMail, IconCalendar } from '@tabler/icons-react';
import { IconCode, IconLanguage, IconBallBasketball, IconMusic, IconPlane, IconDeviceGamepad, IconWalk } from '@tabler/icons-react'; // import necessary icons

// Calculate age based on the birthdate
const birthDate = new Date(2000, 9, 18); // JavaScript months are zero-based, so 9 represents October
const ageInMillis = Date.now() - birthDate.getTime();
const ageInYears = new Date(ageInMillis).getUTCFullYear() - 1970;


// Reusable ListItem component
const ListItem = ({ IconComponent, text }) => (
  <div className='IconLi'>
    <IconComponent className="Icon" />
    <span>{text}</span>
  </div>
);

const Details = ({ className, title, year, institution, place, explanation, company }) => (
  <div className={className}>
    <ul className='educationUL'>
      <li className='TitleDegree'> <h4>{title}</h4></li>
      {company && <li className='liCompany'>{company}</li>}
      <li className='liYear'> <IconCalendar className="IconCalendar" /> {year}</li>
      {institution && <li className='liInstitution'>{institution}</li>}
      {explanation && <li className='liExplanation'>{explanation}</li>}
      
      <li className='liPlace'>{place}</li>
    </ul>
  </div>
)


const About = () => {
  const [data, setData] = useState({ skills: [], languages: [], interests: [] });
  const [imageList, setImageList] = useState([]);
 
  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_ADDRESS}/api/images`)
        .then(response => response.json())
        .then(urls => setImageList(urls))
        .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ADDRESS}/api/aboutdata`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.log(error));
  }, []);


  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % imageList.length);
    }, 3000);

    return () => clearInterval(timer);
  }, [currentIndex, imageList.length]);


  return (

    <section id="about" className="about">
      <div className="container">

        <div className="section-title">
          <h2>About</h2>
          <p className='aboutTitleText'>
            I've been surrounded by technology for as long as I can remember. 
            Video games were my first introduction into the digital world, but as I grew older, it evolved into something bigger, solving real-world problems with algorithms and machine learning. 
            That passion led me to pursue a master's degree in Computer Science, specialising in Artificial Intelligence, where I dove deep into research, state-of-the-art advancements, and the power of AI to reshape industries. 
            But technology wasn’t my only passion. 
            Cars have always been a huge part of my life. 
            Growing up, I spent countless hours in my father’s car dealership, learning the ins and outs of the automotive trade. 
            What started as childhood fascination turned into a deep appreciation for the engineering, design, and business behind every vehicle. 
            This passion ultimately opened the door to an internship at Tesla.
            At Tesla’s Motor design R&D department in Greece, I worked closely with engineers across Europe and the U.S. on the Optimus Robot, contributing to the development of its electrical motor systems. 
            My role wasn’t just about writing code, it was about understanding the needs of mechanical, electrical, and software teams, visualizing complex components, and presenting my work in high-level meetings. 
            It was a fast-paced, challenging environment that pushed me to think critically, adapt quickly, and collaborate across disciplines, skills that I now bring to every project I work on.
</p>
        </div>

        <div className="row">
          <div className="pictureAbout">
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={currentIndex}
                timeout={500}
                classNames="slide"
              >
                
                <img src={`${process.env.REACT_APP_API_ADDRESS}`+`/${imageList[currentIndex]}`} alt="Alexandros Christou" />
              </CSSTransition>
            </SwitchTransition>
          </div>
          <div className="col2" >
            <h3 className='title'>AI & Automotive Enthusiast | Building the Future, One Algorithm at a Time! 🚀</h3>
            <p className="titleSubtext">
              Next to my passion for technology, I have a passion for sports. Today, I combine my love for AI and cars to drive innovation in the automotive space. 
              I’ve developed tools that automate car pricing analysis using machine learning, streamline dealership operations, and transform outdated workflows into data-driven decision-making systems. 
              My goal is to bridge the gap between cutting-edge technology and real-world applications, whether through AI-powered automation, advanced mobility solutions, or the next big leap in automotive tech.
              Further, basketball has been a lifelong mentor. It taught me discipline, perseverance, and the importance of teamwork, values I carry into my work. 
              Studying in Sweden further reinforced these lessons, pushing me out of my comfort zone and exposing me to different cultures, perspectives, and ways of thinking.

            </p>
            <div className="row">
              <div className="col2-1">
                <ul className="first">
                  <li className='IconLi'> <IconCake className="Icon" />  <span>18 October 2000</span></li>
                  <li className='IconLi'> <IconPhoneCall className="Icon" />  <span>+32 477 ** ** **</span></li>
                  <li className='IconLi'> <IconHome className="Icon" />  <span>Brussels, Belgium</span></li>
                </ul>
              </div>
              <div className="col2-1">
                <ul className="first">
                  <li className='IconLi'> <IconBrandAsana className="Icon" />  <span>{ageInYears} years old</span></li>
                  <li className='IconLi'> <IconNotebook className="Icon" />  <span>Master Computer Science</span></li>
                  <li className='IconLi'> <IconMail className="Icon" />  <span>alexchrist@live.be</span></li>
                </ul>
              </div>
              <p>
              
              I may not have years of industry experience, but what I do have is relentless curiosity, a fast learning curve, and the drive to turn challenges into opportunities. 
              I’m open to relocating for the right opportunity and eager to make a real impact.
              Feel free to explore my portfolio.
              Let’s build something great together! 🚀
              </p>
            </div>
            {/* <p>
              This is a selection of skills.
            </p> */}
          </div>
        </div>
       
        <div className="additional-info">
          <div className="grid-container">
            <div className="info-block">
              <h3>Skills</h3>
              <div className='Skills'>
                {data.skills.map((skill) => (
                  <ListItem IconComponent={IconCode} text={skill} />
                ))}
              </div>
            </div>

            <div className="info-block">
              <h3>Languages</h3>
              <div className='Languages'>
                {data.languages.map((language) => (
                  <ListItem IconComponent={IconLanguage} text={language} />
                ))}
              </div>
            </div>

            <div className="info-block">
              <h3>Interests</h3>
              <div className='Interests'>
                {data.interests.map((interest, index) => {
                  const IconComponent = index === 0 ? IconBallBasketball :
                    index === 1 ? IconDeviceGamepad :
                      index === 2 ? IconCar :
                        index === 3 ? IconPlane : IconMusic;
                  return <ListItem IconComponent={IconComponent} text={interest} />
                })}
              </div>
            </div>
          </div>
        </div>
        <section className="education-experience">
          <article className="columEducation">
            <h3>Education</h3>
            <div className="rowExperience">
              {['Master of Science and Engineering in Computer Science: Artificial Intelligence', 'Bachelor of Science in Computer Science', 'Economy and Modern Languages'].map((title, i) => (
                <Details
                  className='educationDiv'
                  title={title}
                  year={['September 2021 - July 2023', 'September 2018 - July 2021', 'September 2012 - July 2018'][i]}
                  institution={['Vrije Universiteit Brussel', 'Vrije Universiteit Brussel', 'Sint-Martinus Scholen ASO'][i]}
                  place={['Brussels, Belgium', 'Brussels, Belgium', 'Asse, Belgium'][i]}
                />
              ))}
            </div>
            <div className="rowExperience">
            <h3>Erasmus</h3>
            <Details
                className='educationDiv'
                title='Master Computer Science/AI Erasmus+ exchange'
                year='August 2022 - Janaury 2023'
                institution='Chalmers University of Technology'
                place='Göteborg, Sweden'
              />
            </div>
          </article>
          <article className="columnExperience">
            <div className="rowExperience">
              <h3>Experience</h3>
              <div className="rowExperience">
              <Details
                  className='educationDiv'
                  title='Independent Software Engineer & Owner'
                  year='Jan 2024 - current'
                  explanation="I manage the digital transformation of my family's car dealership, modernizing operations that were previously managed manually. I developed tools that automate key tasks, such as identifying profitable car listings and analyzing pricing trends using machine learning. I also designed and implemented a structured database system that streamlines inventory management, customer relations, and invoicing, reducing manual work and improving decision-making."
                  company='Auto-Delta'
                  place='Asse, Belgium'
                />
              <Details
                  className='educationDiv'
                  title='Software Engineering Intern'
                  year='March 2024 - July 2024'
                  explanation='Collaborating with a diverse group of engineers to optimize and validate motor designs for various applications, with a main focus on the Optimus robot. My work involves developing software tools in MATLAB to facilitate detailed comparison of measured and simulated data, significantly scaling existing solutions, and innovating new tools to select optimal powertrain systems with scientific certainty.'
                  company='Tesla Inc.'
                  place='Athens, Greece'
                />
                <Details
                  className='educationDiv'
                  title='Software Developer Intern'
                  year='January 2020 - July 2020'
                  explanation='Improved efficiency by 25% of the parts distribution process to technicians for customer repairs by developing software for
                  hand scanners. Tech stack: C#, .NET, HTML, and SQL databases'
                  company='Vaillant Group'
                  place='Brussels, Belgium'
                />
              </div>
            </div>
          </article>
        </section>

      </div>
    </section >
  );
};

export default About;
